var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-item-group',{staticClass:"d-flex flex-wrap mt-3",staticStyle:{"gap":"10px"},attrs:{"active-class":"primary--text"},model:{value:(_vm.activeItem),callback:function ($$v) {_vm.activeItem=$$v},expression:"activeItem"}},[_vm._l((_vm.solicitudesSorted),function(sol,index){return _c('v-item',{key:index,attrs:{"value":sol.CodigoContrato},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"pa-2 d-flex flex-column justify-space-between",attrs:{"outlined":active,"width":"200","height":"200","loading":!sol.IdSolicitud},on:{"click":function($event){$event.stopPropagation();return toggle($event)}}},[_c('div',{staticClass:"d-flex justify-space-between mb-n6"},[(sol.CodigoREE)?_c('v-tooltip',{attrs:{"bottom":"","color":"info"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pa-1 font-weight-bold",staticStyle:{"min-width":"unset"},attrs:{"label":"","small":"","text":"","color":"info"}},'v-btn',attrs,false),on),[_vm._v(_vm._s(sol.CodigoREE))])]}}],null,true)},[_vm._v(" "+_vm._s(sol.NombreFiscal)+" ")]):_vm._e(),(!Number(sol.impagosActivos))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"float-right",attrs:{"color":"warning"}},'v-icon',attrs,false),on),[_vm._v("mdi-alert-outline")])]}}],null,true)},[_vm._v(" Sin impagos, anular ")]):_vm._e()],1),_c('h3',{staticClass:"text-center"},[_vm._v(_vm._s(sol.CodigoContrato))]),_c('div',{staticClass:"d-flex flex-column align-center"},[(sol.fecha)?_c('v-chip',{staticClass:"text-center text-caption",attrs:{"small":"","label":"","outlined":""}},[_vm._v(_vm._s(sol.fecha))]):_vm._e()],1),_c('p',{staticClass:"text-center text-caption text--secondary mb-0 pa-0"},[_vm._v(" "+_vm._s(sol.RazonSocial || [sol.Nombre, sol.Apellido1, sol.Apellido2] .filter(function (x) { return !!x; }) .join(" "))+" ")]),_c('v-card-actions',[_c('v-spacer'),(!sol.anulacion && sol.IdSolicitud)?_c('v-btn',{attrs:{"color":"warning","text":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.anularCorte(sol)}}},[_vm._v("Solicitar anulación")]):(sol.anulacion && sol.IdSolicitud)?_c('v-btn',{attrs:{"plain":"","small":"","color":"info"},on:{"click":function($event){$event.stopPropagation();return _vm.anularAnulacion(sol)}}},[_vm._v("Anulación solicitada")]):(sol.IdSolicitudPropia && !sol.IdSolicitud)?_c('v-btn',{attrs:{"text":"","small":"","color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.anularAnulacion(sol)}}},[_vm._v("Eliminar solicitud")]):_vm._e(),_c('v-spacer')],1)],1)]}}],null,true)})}),_c('v-item',[_c('v-dialog',{attrs:{"scrollable":"","max-width":"700"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-card',_vm._g(_vm._b({staticClass:"pa-2 d-flex flex-column justify-center align-center",staticStyle:{"border-width":"3px","border-style":"dashed","background":"transparent"},attrs:{"outlined":"","width":"200","height":"200"}},'v-card',attrs,false),on),[_c('v-btn',{attrs:{"x-large":"","icon":""}},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-plus")])],1)],1)]}}])},[_c('NuevaSolicitudCorte',{on:{"reload":function($event){return _vm.$emit('reload')}}})],1)],1)],2),_c('ConfirmDialog',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }